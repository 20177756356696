<template>
  <div class="login">
    <v-container style="position: relative; top: 13%" class="text-xs-center">
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success btn-back"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
      <v-card flat>
        <v-card-title
          primary-title
          class="justify-center"
          style="padding-bottom: 5px"
        >
          <h2 class="m-auto register">
            Selamat datang di
            <v-img
              class="logo-er-1"
              src="../assets/logo-er-1.png"
              lazy-src="../assets/logo-er-1.png"
            >
            </v-img>
          </h2>
        </v-card-title>
        <v-form>
          <div
            v-if="submitted && !$v.register_data.nama.required"
            class="invalid-feedback"
          >
            Nama lengkap wajib diisi
          </div>
          <v-text-field
            solo
            placeholder="Nama lengkap"
            name="nama"
            v-model="register_data.nama"
            :class="{ 'is-invalid': submitted && $v.register_data.nama.$error }"
          ></v-text-field>
          <div
            v-if="submitted && !$v.register_data.hp.required"
            class="invalid-feedback"
          >
            Nomor handphone wajib diisi
          </div>
          <v-text-field
            solo
            placeholder="Nomor handphone"
            name="phone"
            type="number"
            v-model="register_data.hp"
            :class="{
              'is-invalid': submitted && $v.register_data.hp.$error,
            }"
          ></v-text-field>
          <div
            v-if="submitted && !$v.register_data.password.required"
            class="invalid-feedback"
          >
            Password wajib diisi
          </div>
          <v-text-field
            solo
            type="password"
            placeholder="Password"
            name="Password"
            v-model="register_data.password"
          ></v-text-field>
          <div
            v-if="submitted && $v.register_data.confirm_password.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.register_data.confirm_password.required"
              >Konfirmasi password wajib diisi</span
            >
            <span v-else-if="!$v.register_data.confirm_password.sameAsPassword"
              >Konfirmasi password dan password tidak sama</span
            >
          </div>
          <v-text-field
            solo
            type="password"
            placeholder="Konfirmasi password"
            name="konfirmasi"
            v-model="register_data.confirm_password"
            :class="{
              'is-invalid':
                submitted && $v.register_data.confirm_password.$error,
            }"
          ></v-text-field>
          <div
            v-if="submitted && $v.register_data.email.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.register_data.email.required"
              >Email wajib diisi</span
            >
            <span v-if="!$v.register_data.email.email">Email invalid</span>
          </div>
          <v-text-field
            solo
            v-model="register_data.email"
            label="E-mail"
            placeholder="Email"
            :class="{
              'is-invalid': submitted && $v.register_data.email.$error,
            }"
          ></v-text-field>
          <div
            v-if="submitted && !$v.register_data.tanggal_lahir.required"
            class="invalid-feedback"
          >
            Tanggal lahir wajib diisi
          </div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                solo
                v-model="register_data.tanggal_lahir"
                label="Tanggal lahir (dd/mm/yyyy)"
                hint="DD/MM/YYYY format"
                prepend-icon="icon-date"
                readonly
                v-bind="attrs"
                v-on="on"
                :class="{
                  'is-invalid':
                    submitted && $v.register_data.tanggal_lahir.$error,
                }"
              ></v-text-field>
            </template>
            <v-date-picker
              ref="picker"
              v-model="register_data.tanggal_lahir"
              :max="new Date().toISOString().substr(0, 10)"
              min="1950-01-01"
              @change="save"
              :class="{
                'is-invalid':
                  submitted && $v.register_data.tanggal_lahir.$error,
              }"
              no-title
              scrollable
            ></v-date-picker>
          </v-menu>
          <v-row>
            <v-col cols="12" class="v-row-align-center v-row-no-padding">
              <div
                v-if="submitted && !$v.register_data.gender.required"
                class="invalid-feedback"
              >
                Jenis kelamin wajib diisi
              </div>
            </v-col>
            <v-col cols="4" class="v-row-align-center v-row-no-padding">
              <v-subheader>Jenis kelamin</v-subheader>
            </v-col>
            <v-col cols="8" class="v-row-no-padding">
              <v-radio-group v-model="register_data.gender" row>
                <v-radio label="Laki-laki" value="L"></v-radio>
                <v-radio label="Perempuan" value="P"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <alert v-if="error.phone != ''" :message="error.phone" />
          <v-card-actions>
            <v-btn @click="register" dark large block class="btn-register"
              >Daftar Akun</v-btn
            >
          </v-card-actions>
          <p class="text-center">
            Dengan klik register, anda telah mempercayakan informasi anda untuk
            kami simpan dan gunakan.<br /><br />
            Keamanan data anda terlindungi sesuai dengan Syarat & Ketentuan dan
            Privasi Er-1.
          </p>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { required, email, sameAs } from "vuelidate/lib/validators";
import { VerifikasiOtp } from "../services/api";
import Alert from "../components/Alert";
export default {
  components: {
    Alert,
  },
  data: () => ({
    date: null,
    menu: false,
    submitted: false,
    error: {
      phone: "",
    },
    slice_hp: "",
    register_data: {
      nama: "",
      hp: "",
      gender: "",
      email: "",
      tanggal_lahir: "",
      password: "",
    },
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  validations: {
    register_data: {
      nama: { required },
      hp: { required },
      gender: { required },
      email: { required, email },
      tanggal_lahir: { required },
      password: { required },
      confirm_password: { required, sameAsPassword: sameAs("password") },
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date);
    },
    register() {
      this.slice_hp = this.register_data.hp.slice(
        this.register_data.hp.length - 4
      );
      console.log(this.register_data);
      this.submitted = true;

      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        VerifikasiOtp(this.register_data)
          .then((data) => {
            console.log(data);
            this.$router.push({
              name: "verifikasi",
              params: {
                no_hp: this.register_data.hp,
                nama: this.register_data.nama,
                gender: this.register_data.gender,
                email: this.register_data.email,
                tanggal_lahir: this.register_data.tanggal_lahir,
                password: this.register_data.password,
                slice_hp: this.slice_hp,
              },
            });
          })
          .catch((err) => {
            console.log(err.response.status);
            console.log(err.response);
            console.log(err["code"]);
            if (err.response.status == 409) {
              this.error.phone = err.response.data.message;
            }
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    hasHistory() {
      return window.history.length > 2;
    },
  },
};
</script>
<style>
@import "../assets/css/register.component.scss";
</style>
