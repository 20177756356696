var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login"},[_c('v-container',{staticClass:"text-xs-center",staticStyle:{"position":"relative","top":"13%"}},[_c('button',{staticClass:"my-5 btn btn-outline-success btn-back",attrs:{"type":"button"},on:{"click":function($event){_vm.hasHistory() ? _vm.$router.go(-1) : _vm.$router.push('/')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" Back ")],1),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"justify-center",staticStyle:{"padding-bottom":"5px"},attrs:{"primary-title":""}},[_c('h2',{staticClass:"m-auto register"},[_vm._v(" Selamat datang di "),_c('v-img',{staticClass:"logo-er-1",attrs:{"src":require("../assets/logo-er-1.png"),"lazy-src":require("../assets/logo-er-1.png")}})],1)]),_c('v-form',[(_vm.submitted && !_vm.$v.register_data.nama.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Nama lengkap wajib diisi ")]):_vm._e(),_c('v-text-field',{class:{ 'is-invalid': _vm.submitted && _vm.$v.register_data.nama.$error },attrs:{"solo":"","placeholder":"Nama lengkap","name":"nama"},model:{value:(_vm.register_data.nama),callback:function ($$v) {_vm.$set(_vm.register_data, "nama", $$v)},expression:"register_data.nama"}}),(_vm.submitted && !_vm.$v.register_data.hp.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Nomor handphone wajib diisi ")]):_vm._e(),_c('v-text-field',{class:{
            'is-invalid': _vm.submitted && _vm.$v.register_data.hp.$error,
          },attrs:{"solo":"","placeholder":"Nomor handphone","name":"phone","type":"number"},model:{value:(_vm.register_data.hp),callback:function ($$v) {_vm.$set(_vm.register_data, "hp", $$v)},expression:"register_data.hp"}}),(_vm.submitted && !_vm.$v.register_data.password.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Password wajib diisi ")]):_vm._e(),_c('v-text-field',{attrs:{"solo":"","type":"password","placeholder":"Password","name":"Password"},model:{value:(_vm.register_data.password),callback:function ($$v) {_vm.$set(_vm.register_data, "password", $$v)},expression:"register_data.password"}}),(_vm.submitted && _vm.$v.register_data.confirm_password.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.register_data.confirm_password.required)?_c('span',[_vm._v("Konfirmasi password wajib diisi")]):(!_vm.$v.register_data.confirm_password.sameAsPassword)?_c('span',[_vm._v("Konfirmasi password dan password tidak sama")]):_vm._e()]):_vm._e(),_c('v-text-field',{class:{
            'is-invalid':
              _vm.submitted && _vm.$v.register_data.confirm_password.$error,
          },attrs:{"solo":"","type":"password","placeholder":"Konfirmasi password","name":"konfirmasi"},model:{value:(_vm.register_data.confirm_password),callback:function ($$v) {_vm.$set(_vm.register_data, "confirm_password", $$v)},expression:"register_data.confirm_password"}}),(_vm.submitted && _vm.$v.register_data.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.register_data.email.required)?_c('span',[_vm._v("Email wajib diisi")]):_vm._e(),(!_vm.$v.register_data.email.email)?_c('span',[_vm._v("Email invalid")]):_vm._e()]):_vm._e(),_c('v-text-field',{class:{
            'is-invalid': _vm.submitted && _vm.$v.register_data.email.$error,
          },attrs:{"solo":"","label":"E-mail","placeholder":"Email"},model:{value:(_vm.register_data.email),callback:function ($$v) {_vm.$set(_vm.register_data, "email", $$v)},expression:"register_data.email"}}),(_vm.submitted && !_vm.$v.register_data.tanggal_lahir.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Tanggal lahir wajib diisi ")]):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:{
                'is-invalid':
                  _vm.submitted && _vm.$v.register_data.tanggal_lahir.$error,
              },attrs:{"solo":"","label":"Tanggal lahir (dd/mm/yyyy)","hint":"DD/MM/YYYY format","prepend-icon":"icon-date","readonly":""},model:{value:(_vm.register_data.tanggal_lahir),callback:function ($$v) {_vm.$set(_vm.register_data, "tanggal_lahir", $$v)},expression:"register_data.tanggal_lahir"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",class:{
              'is-invalid':
                _vm.submitted && _vm.$v.register_data.tanggal_lahir.$error,
            },attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1950-01-01","no-title":"","scrollable":""},on:{"change":_vm.save},model:{value:(_vm.register_data.tanggal_lahir),callback:function ($$v) {_vm.$set(_vm.register_data, "tanggal_lahir", $$v)},expression:"register_data.tanggal_lahir"}})],1),_c('v-row',[_c('v-col',{staticClass:"v-row-align-center v-row-no-padding",attrs:{"cols":"12"}},[(_vm.submitted && !_vm.$v.register_data.gender.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Jenis kelamin wajib diisi ")]):_vm._e()]),_c('v-col',{staticClass:"v-row-align-center v-row-no-padding",attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v("Jenis kelamin")])],1),_c('v-col',{staticClass:"v-row-no-padding",attrs:{"cols":"8"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.register_data.gender),callback:function ($$v) {_vm.$set(_vm.register_data, "gender", $$v)},expression:"register_data.gender"}},[_c('v-radio',{attrs:{"label":"Laki-laki","value":"L"}}),_c('v-radio',{attrs:{"label":"Perempuan","value":"P"}})],1)],1)],1),(_vm.error.phone != '')?_c('alert',{attrs:{"message":_vm.error.phone}}):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"btn-register",attrs:{"dark":"","large":"","block":""},on:{"click":_vm.register}},[_vm._v("Daftar Akun")])],1),_c('p',{staticClass:"text-center"},[_vm._v(" Dengan klik register, anda telah mempercayakan informasi anda untuk kami simpan dan gunakan."),_c('br'),_c('br'),_vm._v(" Keamanan data anda terlindungi sesuai dengan Syarat & Ketentuan dan Privasi Er-1. ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }